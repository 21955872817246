import React from 'react';
import styled from 'styled-components';

import { Section, SectionTitle } from './Layout';
import { StaticImage } from 'gatsby-plugin-image';

const REVIEWS = [
  {
    content:
      'Szeroki asortyment i błyskawiczna realizacja zamówienia. Super jakość odzieży, bardzo miły i sprawny kontakt. Wszystko doskonale zorganizowane. Przed nami na pewno jeszcze wiele wspólnych projektów – polecam.',
    client: 'RAW Centrum Treningowe',
    role: '',
  },
  {
    content:
      'Sprawdzona jakość odzieży – idealnie sprawdza się podczas treningu. Zamówienia realizowane profesjonalnie i solidnie. Pełne wsparcie na każdym etapie – od pomocy w graficznym przygotowaniu projektu, poprzez doradztwo w doborze asortymentu aż po realizację. Świetny kontakt – polecamy i na pewno będziemy wracać z nowymi pomysłami.',
    client: 'CrossFit Mokotów',
    role: '',
  },
  {
    content:
      'Współpracujemy od kilku lat przy produkcji odzieży zarówno na eventy jak i na cele promocyjne firmy. Jakość produktów jak i wykonanych nadruków nigdy nas nie zawiodła. Polecamy w 100%.”',
    client: 'Steelstorm',
    role: '',
  },
  {
    content:
      '3MIGA Custom Wear to nie tylko produkcja sportowa. Współpracujemy z Kasią od samego początku przy niestandardowych projektach - nadrukach na koszulkach dla Panien młodych czy pokrowcach na suknie. Nigdy się nie zawiodłyśmy, a nasze klientki doceniają jakość wykonania. Polecamy w 100%.',
    client: 'Ksis Wedding Atelier',
    role: '',
  },
];

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -32px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 400px;
  }
`;

const Item = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible;
  z-index: 1;
  padding: 0 32px;

  @media (max-width: 1200px) {
    flex: 0 0 50%;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
    padding: 0;
  }
`;

const QueteTop = styled.div`
  position: absolute;
  top: 0;
  left: -24px;
  z-index: 2;

  @media (max-width: 768px) {
    left: 0;
  }
`;

const QueteBottom = styled.div`
  position: absolute;
  right: -24px;
  bottom: 0;
  z-index: 2;

  @media (max-width: 768px) {
    right: 0;
  }
`;

const Content = styled.div`
  margin-bottom: 40px;
  position: relative;
  padding: 24px 0;

  @media (max-width: 768px) {
    padding: 24px;
    margin-bottom: 16px;
  }
`;

const Text = styled.p`
  font-style: italic;
`;

const Client = styled.p`
  text-align: right;
  font-weight: 300;
  align-self: flex-end;
`;

const Role = styled.p`
  text-align: right;
  font-style: italic;
  font-size: 13px;
  align-self: flex-end;
`;

const Quote1 = () => (
  <StaticImage src='../images/icons/quote1.png' width={22} height={22} alt='' />
);
const Quote2 = () => (
  <StaticImage src='../images/icons/quote2.png' width={22} height={22} alt='' />
);

const Reviews = () => {
  return (
    <Section>
      <SectionTitle>OPINIE</SectionTitle>
      <Wrapper>
        {REVIEWS.map(({ content, client, role }, i) => {
          const num = i + 1;
          const quoteComponent = num % 2 !== 0 ? <Quote1 /> : <Quote2 />;
          return (
            <Item key={client + i}>
              <Content>
                <QueteTop>{quoteComponent}</QueteTop>
                <Text>{content}</Text>
                <QueteBottom>{quoteComponent}</QueteBottom>
              </Content>
              <Client>{client}</Client>
              {role && <Role>{role}</Role>}
            </Item>
          );
        })}
      </Wrapper>
    </Section>
  );
};

export { Reviews };
