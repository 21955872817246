import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Section, Text, SectionTitle } from './Layout';

import { Gallery as GalleryWrapper, Item } from 'react-photoswipe-gallery';

const IMAGES_1 = [
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/as_box.jpg',
    width: '1536',
    height: '2048',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/biała_podlaska.jpg',
    width: '1536',
    height: '2048',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/box88.jpg',
    width: '1536',
    height: '2048',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/czapki.jpg',
    width: '1536',
    height: '2048',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/kfd.jpg',
    width: '1344',
    height: '1793',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/miasteczko.jpg',
    width: '1536',
    height: '2048',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/onpnt.jpg',
    width: '1536',
    height: '2048',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/raw.jpg',
    width: '1536',
    height: '2048',
  },
];

const IMAGES_2 = [
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/steelstorm.jpg',
    width: '2048',
    height: '1240',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/worldline.jpg',
    width: '1536',
    height: '930',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/gliwice.jpg',
    width: '2048',
    height: '1240',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/perlan.jpg',
    width: '2048',
    height: '1240',
  },
];

const smallItemStyles = {
  cursor: 'pointer',
  objectFit: 'cover',
  width: '100%',
  maxHeight: '100%',
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    grid-gap: 8px;
    margin-bottom: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Wrapper2 = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-gap: 16px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    grid-gap: 8px;
    margin-bottom: 24px;
    grid-template-columns: 2fr 2fr;
  }
`;

const ImgWrapper = styled.div`
  margin: 0 4px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
`;

const Copy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const GalleryItem = ({ url, width, height }) => {
  return (
    <Item original={url} thumbnail={url} width={width} height={height}>
      {({ ref, open }) => (
        <img style={smallItemStyles} src={url} ref={ref} onClick={open} />
      )}
    </Item>
  );
};

const Gallery = () => {
  return (
    <Section>
      <SectionTitle>Realizacje</SectionTitle>
      <GalleryWrapper options={{ shareEl: false, fullscreenEl: false }}>
        <Wrapper>
          {IMAGES_1.map(({ url, width, height }) => (
            <GalleryItem key={url} url={url} width={width} height={height} />
          ))}
        </Wrapper>
        <Wrapper2>
          {IMAGES_2.map(({ url, width, height }) => (
            <GalleryItem key={url} url={url} width={width} height={height} />
          ))}
        </Wrapper2>
      </GalleryWrapper>
      <Copy>
        <Text>WIĘCEJ ZNAJDZIESZ NA</Text>
        <Link
          href='https://instagram.com/3miga_made_in_genius?utm_medium=copy_link'
          alt='instagram page'
          target='_blank'
        >
          <ImgWrapper>
            <StaticImage src='../images/icons/ig.png' width={35} alt='' />
          </ImgWrapper>
          <Text>@3miga_made_in_genius</Text>
        </Link>
      </Copy>
    </Section>
  );
};

export { Gallery };
