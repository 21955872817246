import * as React from "react"
import { Layout, Nav, Offer, Gallery, Reviews, Partners, Footer } from '../components';
import '../style.css';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { initializeApp } from "firebase/app";
import { Helmet } from "react-helmet";

const firebaseConfig = {
  apiKey: "AIzaSyDI6wmfVbUqEtiF5AtjlICcJAnBFrVW1DI",
  authDomain: "miga-33c73.firebaseapp.com",
  projectId: "miga-33c73",
  storageBucket: "miga-33c73.appspot.com",
  messagingSenderId: "978138062246",
  appId: "1:978138062246:web:7525256d9e33d20e528367"
};

initializeApp(firebaseConfig);

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
          <title>3 Miga Custom Wear</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="index, follow"></meta>
          <meta
            name="description"
            content="nadruki na koszulki warszawa, koszulki z logo warszawa, ubrania z logo firmy, odzięz dla firm na zamowienie"
          />
          <meta
            name="keywords"
            content="nadruki, koszulki, crossfit, warszawa, logo firmowe, odziez firmowa, czapki z logo, skarpety z logo, ubrania z logo, koszulki aleja prymasa tysiąclecia"
          ></meta>
          <link rel="canonical" href="https://www.3miga.pl/" />
          <meta property="og:image:width" content="1200" />
		      <meta property="og:image:height" content="630" />
          <meta
            property="og:image"
            content="https://3miga.s3.eu-west-1.amazonaws.com/logo.png"
          />
          <meta
            property="og:description"
            content="Koszulki na zamównie z logiem Twojej firmy!"
          />
		      <meta property="og:url" content="https://www.3miga.pl/" />
		      <meta property="og:title" content="3 Miga Custom Wear" />
      </Helmet>
      <Nav />
      <Offer />
      <Gallery />
      <Partners />
      <Reviews />
      <Footer />
    </Layout>
  )
}

export default IndexPage
