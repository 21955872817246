import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import { useMediaQuery } from './hooks/useMediaQuery';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 0 0;
`;
const Logo = styled.img`
  width: 100px;
  @media (max-width: 768px) {
    width: 70px;
  }
`;
const List = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
`;
const ListItem = styled.li`
  padding-left: 16px;
  font-size: 20px;
`;
const Trigger = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
`;

const Nav = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Header>
      <Logo
        src='https://3miga.s3.eu-west-1.amazonaws.com/logo_main.png'
        alt='3 miga custom wear'
      />
      <nav>
        <List>
          {!isMobile && (
            <ListItem>
              <Trigger>KONTAKT</Trigger>
            </ListItem>
          )}
          <ListItem>
            <Trigger
              href='https://www.facebook.com/profile.php?id=100063658053964'
              alt='facebook page'
              target='_blank'
            >
              <StaticImage src='../images/icons/fb.png' width={35} alt='' />
            </Trigger>
          </ListItem>
          <ListItem>
            <Trigger
              href='https://instagram.com/3miga_made_in_genius?utm_medium=copy_link'
              alt='instagram page'
              target='_blank'
            >
              <StaticImage src='../images/icons/ig.png' width={35} alt='' />
            </Trigger>
          </ListItem>
        </List>
      </nav>
    </Header>
  );
};

export { Nav };
