import React from 'react';
import styled from 'styled-components';
import { Section, SectionTitle } from './Layout';

const PARTNERS = [
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Antydoping.png',
    alt: 'antydoping',
    homepage: 'https://antydopinglab.pl/pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Steelstorm.png',
    alt: 'Steelstorm',
    homepage: 'https://steelstorm.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/BlackStar.png',
    alt: 'Crossfit Black Start',
    homepage: 'http://www.crossfitblackstar.com.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/BNPparibas.png',
    alt: 'BNP Paribas',
    homepage: 'https://www.bnpparibas.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Box88.png',
    alt: 'Box 88',
    homepage: 'https://box88.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Gliwice.png',
    alt: 'Crossfit Gliwice',
    homepage: '',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/KFD.png',
    alt: 'KFD',
    homepage: 'https://sklep.kfd.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Krzyki.png',
    alt: 'Krzyki 181',
    homepage: 'https://krzyki181.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Ksis.png',
    alt: 'Ksis',
    homepage: 'https://ksis.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Miasteczko.png',
    alt: 'Miasteczko Crossfit',
    homepage: 'https://www.miasteczkocrossfit.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Mokotow.png',
    alt: 'Crossift Mokotów',
    homepage: 'https://www.facebook.com/crossfitmokotow',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Perlan.png',
    alt: 'Perlan',
    homepage: 'https://www.perlan.com.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Raiffeisen.png',
    alt: 'Raiffeissen',
    homepage: '',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Raw.png',
    alt: 'Raw Centrum Treningowe',
    homepage: 'https://www.facebook.com/RawCentrumTreningowe/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/AsBox.png',
    alt: 'As box',
    homepage: 'https://asbox.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Torun.png',
    alt: 'Crossfit Toruń',
    homepage: 'https://crossfit-torun.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Webtalk.png',
    alt: 'Webtalk Agency',
    homepage: 'https://webtalk.pl/',
  },
  {
    url: 'https://3miga.s3.eu-west-1.amazonaws.com/partners/Worldline.png',
    alt: 'Worldline',
    homepage: 'https://worldline.com/en/home.html',
  },
];

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  flex: 0 0 11%;
  display: flex;
  align-items: center;
  padding: 0 24px;

  img {
    width: 100%;
  }

  @media (max-width: 1200px) {
    flex: 0 0 16%;
    padding: 16px;
    justify-content: center;
  }

  @media (max-width: 768px) {
    flex: 0 0 25%;
    justify-content: center;
  }
`;

const Partners = () => {
  return (
    <Section>
      <SectionTitle>ZAUFALI NAM</SectionTitle>
      <Wrapper>
        {PARTNERS.map(({ url, alt, homepage }) => (
          <Item key={url}>
            <a href={homepage} target='_blank'>
              <img src={url} alt={alt} />
            </a>
          </Item>
        ))}
      </Wrapper>
    </Section>
  );
};

export { Partners };
