import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Section, SectionTitle, Text, Link } from './Layout';
import { Form } from './Form';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContactDetails = styled.div`
  flex: 1;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  width: 100%;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Icon = styled.div`
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
  padding-right: 8px;
`;

const Footer = () => {
  return (
    <Section>
      <SectionTitle>Kontakt</SectionTitle>
      <Layout>
        <Form />
        <ContactDetails>
          <ContactItem>
            <Icon>
              <StaticImage src='../images/icons/user.png' width={28} alt='' />
            </Icon>
            <Text>3MIGA Custom Wear</Text>
          </ContactItem>
          <ContactItem>
            <Icon>
              <StaticImage src='../images/icons/house.png' width={30} alt='' />
            </Icon>
            <Text>
              Aleja Prymasa Tysiąclecia 76D/14 <br />
              01-424 Warszawa <br />
            </Text>
          </ContactItem>
          <ContactItem>
            <Icon>
              <StaticImage src='../images/icons/email.png' width={28} alt='' />
            </Icon>
            <Link href='mailto:biuro@3miga.pl'>biuro@3miga.pl</Link>
          </ContactItem>
          <ContactItem>
            <Icon>
              <StaticImage src='../images/icons/phone.png' width={28} alt='' />
            </Icon>
            <Link href='tel:+48571499903'>571 499 903</Link>
          </ContactItem>
        </ContactDetails>
      </Layout>
    </Section>
  );
};

export { Footer };
