import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 32px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 24px;
  }
`;

const Section = styled.section`
  margin: 80px auto;
  width: 100%;

  @media (max-width: 768px) {
    margin: 50px auto;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.p`
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  margin-bottom: 80px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  ::before,
  ::after {
    content: '';
    width: 30px;
    height: 3px;
    background-color: #f9ca24;
    position: absolute;
    top: 50%;
  }
  ::before {
    left: -50px;
  }
  ::after {
    right: -50px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 60px;
  }
`;

const Link = styled.a`
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
  word-spacing: 2px;
  color: #000;
  text-decoration: none;
`;

const Text = styled.p`
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
  word-spacing: 2px;
`;

const SectionTitle = ({ children }) => {
  return (
    <TitleWrapper>
      <Title>{children}</Title>
    </TitleWrapper>
  );
};

const Layout = ({ children }) => {
  return <Container>{children}</Container>;
};

const Field = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;
const Label = styled.label`
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 8px;
`;
const Input = styled.input`
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 8px;
  padding: 8px;

  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  background-color: rgb(232, 240, 254);
  border-radius: 4px;
`;
const Textarea = styled.textarea`
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px;
  margin-bottom: 8px;
  height: 200px;
  resize: vertical;

  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  background-color: rgb(232, 240, 254);
  border-radius: 4px;
`;

const InputElement = ({ onChange, label, type, name, value }) => {
  return (
    <Field>
      <Label>{label}</Label>
      <Input name={name} onChange={onChange} value={value} type={type} />
    </Field>
  );
};

const TextareaElement = ({ onChange, label, type, name, value }) => {
  return (
    <Field>
      <Label>{label}</Label>
      <Textarea name={name} onChange={onChange} value={value} />
    </Field>
  );
};

const Button = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;

  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  border-radius: 4px;

  background-color: #f9ca24;
`;

const SubmitButton = ({ onClick, children }) => {
  return (
    <Button onClick={onClick} type='submit'>
      {children}
    </Button>
  );
};

export {
  Layout,
  Section,
  Text,
  SectionTitle,
  InputElement,
  TextareaElement,
  SubmitButton,
  Link,
};
