import React from 'react';
import styled from 'styled-components';
import { Section, Text, SectionTitle } from '.';

const OFFER_LIST = [
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/bluzy.png',
    name: 'bluzy',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/czapki_z_daszkiem.png',
    name: 'czapki z daszkiem',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/czapki_zimowe.png',
    name: 'czapki zimowe',
    color: 'yellow',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/kamizelki.png',
    name: 'kamizelki',
    color: 'yellow',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/koszulki_polo.png',
    name: 'koszulki polo',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/kurtki.png',
    name: 'kurtki',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/longsleeve.png',
    name: 'longsleeve',
    color: 'yellow',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/odziez_dziecieca.png',
    name: 'odzieŻ dziecięca',
    color: 'yellow',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/odziez_treningowa.png',
    name: 'odzieŻ treningowa',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/polary.png',
    name: 'polary',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/skarpetki.png',
    name: 'skarpetki',
    color: 'yellow',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/spodenki.png',
    name: 'spodenki',
    color: 'yellow',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/spodnie_dresowe.png',
    name: 'spodnie dresowe',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/tank_topy.png',
    name: 'tank topy',
    color: 'blue',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/torby-i-placaki.png',
    name: 'torby i plecaki',
    color: 'yellow',
  },
  {
    src: 'https://3miga.s3.eu-west-1.amazonaws.com/offer/tshirty.png',
    name: 't-shirty',
    color: 'yellow',
  },
];

const BG_COLORS = {
  grey: '#f1f2f6',
  yellow: '#f9ca24',
  blue: 'rgb(232, 240, 254)',
};

const OfferList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
`;

const OfferItem = styled.div`
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex: 0 0 25%;
  }

  @media (max-width: 500px) {
    flex: 0 0 50%;
  }
`;

const Name = styled(Text)`
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 17px;

  @media (max-width: 768px) {
    font-size: 15px;
    max-width: 130px;
  }
`;

const Icon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${(props) => BG_COLORS[props.bgColor]};
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    background-color: ${(props) => BG_COLORS[props.hoverBg]};
  }

  img {
    width: 50px;
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;

    img {
      width: 40px;
    }
  }
`;

const Offer = () => {
  return (
    <Section>
      <SectionTitle>NASZA OFERTA</SectionTitle>
      <OfferList>
        {OFFER_LIST.map((offer, i) => {
          return (
            <OfferItem kye={offer.src}>
              <Name>{offer.name}</Name>
              <Icon
                bgColor={offer.color}
                hoverBg={offer.color === 'blue' ? 'yellow' : 'blue'}
              >
                <img src={offer.src} alt={offer.name} />
              </Icon>
            </OfferItem>
          );
        })}
      </OfferList>
    </Section>
  );
};

export { Offer };
