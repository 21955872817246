import React, { useState } from 'react';
import styled from 'styled-components';
import { InputElement, TextareaElement, SubmitButton } from './Layout';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateEmail } from './utils';
import BeatLoader from 'react-spinners/BeatLoader';

const ContactForm = styled.form`
  max-width: 500px;
  flex: 1;
  padding-right: 32px;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 40px;
    width: 100%;
  }
`;

const Terms = styled.p`
  font-size: 13px;
  margin-bottom: 24px;
  font-weight: 300;
`;

const DEFAULT_FORM = {
  name: '',
  email: '',
  content: '',
};

const TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  progress: undefined,
};

const Form = () => {
  const [form, setForm] = useState(DEFAULT_FORM);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = ({ target: { name, value } }) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const functions = getFunctions();
    const sendEmail = httpsCallable(functions, 'sendEmail');
    const { name, email, content } = form;

    if (!name.trim() || !email.trim() || !content.trim()) {
      toast.warning('Wszystkie pola wymagane', TOAST_OPTIONS);
      return;
    }

    if (!validateEmail(email)) {
      toast.warning('Niepoprawny email', TOAST_OPTIONS);
      return;
    }

    setIsLoading(true);

    sendEmail({
      email: form.email,
      name: form.name,
      content: form.content,
    })
      .then((res) => {
        toast.success('Wiadomość wysłana!', TOAST_OPTIONS);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(
          'Coś poszło nie tak, spróbuj ponownie później',
          TOAST_OPTIONS
        );
      })
      .finally(() => {
        setForm(DEFAULT_FORM);
        setIsLoading(false);
      });
  };

  return (
    <ContactForm onSubmit={onSubmit}>
      <InputElement
        label='twoje imię'
        type='text'
        onChange={handleOnChange}
        name='name'
        value={form.name}
      />
      <InputElement
        label='email'
        type='email'
        onChange={handleOnChange}
        name='email'
        value={form.email}
      />
      <TextareaElement
        label='wiadomość'
        onChange={handleOnChange}
        name='content'
        value={form.content}
      />
      <Terms>
        Administratorem danych osobowych jest Made in Genius, Al. Prymasa
        Tysiąclecia 76D/14, 01-424 Warszawa. Dane wpisane w formularzu
        kontaktowym będą przetwarzane wyłącznie w celu odpowiedzi na przesłane
        zapytanie.
      </Terms>
      <SubmitButton onClick={onSubmit}>
        {isLoading ? <BeatLoader color='#fff' size={12} /> : 'Wyślij'}
      </SubmitButton>
      <ToastContainer />
    </ContactForm>
  );
};

export { Form };
